@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Repetition';
	src: url('../fonts/Repetition-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'PennStation';
	src: url('../fonts/PennStation.ttf') format('truetype');
}

html {
	position: relative;
	min-height: 100%;
}
body {
	margin: 0;
	font-family: 'Poppins';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #0a0c10;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

#divider-hr {
	color: #202930;
	height: 2px;
	margin: 0;
}

.modal-open {
	overflow: hidden;
}

#store-item-modal {
	width: 800px;
	height: 720px;
	padding: 20px;
	border-radius: 10px;
	overflow: hidden;
}

#view-nft-modal {
	width: 800px;
	height: 840px;
	padding: 20px;
	border-radius: 10px;
	overflow: hidden;
}

#view-nft-modal,
#store-item-modal hr {
	margin-top: 0px;
}

@media screen and (max-width: 991px) {
	#view-nft-modal,
	#store-item-modal {
		width: 100%;
		height: 100%;
	}
}

.modal-content {
	border-radius: 10px;
	box-shadow: 2px 2px 38px rgba(0, 0, 0, 0.5);
	background-color: #0e1419;
	color: white;
}

#navbar-container {
	min-height: 70px;
	width: 100%;
}

.navbar {
	background-color: #0f161c !important;
	min-height: 75px;
	padding-left: 3vw;
	padding-right: 3vw;
}

.navbar-dropdown {
	text-align: center;
}
.dropdown-menu {
	background-color: #0f161c !important;
}

.nav-link {
	color: white !important;
}

#modal-dropdown {
	width: 92px;
	height: 34px;
	background-color: #0b0f13;
	border-radius: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	font-size: 13px;
	font-weight: 400;
	padding: 10px 7px;
}

#modal-dropdown-menu {
	font-size: 13px;
}

#modal-dropdown-menu .dropdown-item {
	margin-bottom: 5px;
}

.dropdown-item {
	color: white;
}

.dropdown-menu :hover {
	color: white;
	background-color: transparent;
}

.input-row {
	padding-bottom: 5px;
}

#skvllpvnkz-logo {
	width: 200px;
}

#delivery-details {
	padding-top: 5px;
}

.swal2-popup {
	background-color: #172128 !important;
	box-shadow: 2px 10px 10px 2px rgba(10, 10, 10, 1);
	color: #509c78 !important;
	padding-top: 30px !important;
	padding-bottom: 30px !important;
	width: 400px !important;
}

.swal2-title,
.swal2-html-container {
	color: white !important;
	font-size: 24px !important;
}

.swal2-html-container {
	font-size: 16px !important;
}

.swal2-confirm {
	background-color: #1ca595 !important;
	color: black !important;
	width: 120px;
	font-weight: 600 !important;
}

#product-image {
	border-radius: 10px;
}

#skvllpvnkz-title {
	float: left;
}

#banner-view-button-1 {
	background-color: transparent;
	border: none;
	box-shadow: none;
	float: right;
}

#banner-view-button-2 {
	background-color: transparent;
	border: none;
	box-shadow: none;
	float: left;
	padding-left: 0px;
}
/* Banner on Discover Page Desktop */
#active {
	background-color: transparent;
	border-top: none;
	border-left: none;
	border-right: none;
	border-radius: 0px;
	box-shadow: none;
}
#active div div {
	filter: brightness(100%);
	-webkit-filter: brightness(100%);
	-moz-filter: brightness(100%);
}

.discover-banner-button {
	display: flex;
	position: absolute;
	width: 100%;
	height: 40vh;
	margin-block-end: 10rem;
}
.discover-banner-button[id^='inactive'] {
	display: none;
}

#inactive {
	background-color: transparent;
	border: none;
	box-shadow: none;
}
.discover-banner-button-desktop {
	text-transform: capitalize;
}
.discover-banner-button-desktop[id^='active'] {
	border-bottom: #6ac99b 3px solid;
}
.discover-banner-button-desktop[id^='inactive'] div div {
	display: none;
}
#inactive:hover div div {
	filter: brightness(100%);
	-webkit-filter: brightness(100%);
	-moz-filter: brightness(100%);
}

.divider-display-text-on-image {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 4px 8px;
	transition: all 0.2s ease;
}
.divider-display-text-on-image h1 {
	padding: 0;
	margin: 0;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 48px;
}
.divider-display-text-on-image h1 {
	text-transform: capitalize;
}
.discover-banner-button-desktop[id^='inactive']:hover
	.divider-display-text-on-image {
	background: #7663c3;
}
@media only screen and (max-width: 1024px) {
	.divider-display-text-on-image h1 {
		font-size: 40px;
	}
	.discover-banner-button {
		display: none;
	}
	.discover-banner-button-desktop {
		display: none;
	}
}
@media only screen and (max-width: 1024px) {
	.divider-display-text-on-image h1 {
		font-size: 30px;
	}
}
/* Banner on Discover Page Mobile */
.swiper {
	height: 30vh !important;
}
.swiper-pagination {
	text-align: right !important;
	padding: 8px 16px;
}

.swiper-pagination-bullet {
	width: 20px !important;
	height: 20px !important;
	opacity: 0.7 !important;
	background: #000000 !important;
}
.swiper-pagination-bullet-active {
	opacity: 1 !important;
	background: #7663c3 !important;
}
.discover-banner-button-mobile-container {
	display: none;
}
.discover-banner-button-mobile {
	width: 100vw;
	height: 40vh;
	padding: 0;
}
.discover-banner-button-mobile[id^='active'] .divider-display-text-on-image {
	background: #7663c3;
}
.discover-banner-button-mobile[id^='inactive']:hover
	.divider-display-text-on-image {
	background: #7663c3;
}
#filter-section-icon {
	float: right;
}
@media only screen and (max-width: 1024px) {
	.discover-banner-button-mobile-container {
		display: block;
	}
}
@media screen and (max-width: 1024px) {
	#skvllpvnkz-title {
		float: none;
		text-align: center;
	}
}
@media screen and (max-width: 768px) {
	#skvllpvnkz-logo {
		width: 30px;
	}
}

@media screen and (max-width: 320px) {
	#skvllpvnkz-title {
		width: 260px;
	}
	label {
		font-size: 10px;
	}

	#view-nft-modal,
	#store-item-modal {
		padding: 12px;
	}
}

@media screen and (max-height: 1024px) {
	.modal {
		top: 0;
	}
}
